import { SessionURLs } from '../assets/types';
import { getGTData, getUserChildObject } from '../state/UserDataSlice';

// ================= TYPES =====================

/**
 * This is the complete user data object, as stored in the UCS. It's not fully typed.
 */
export interface UCSDownloadResponse {
  gtData: {
    /** Not the UUID. */
    id: number;
    /** Where data used within GT programs is stored. */
    gtdatastring: {
      [key: string]: any;
      platform?: 'web' | 'unset';
      currentSession?: SessionURLs | 'Initial';
      moodJournalEntryDates?: number[][];
      moodJournalEmoEntries?: number[];
      mjRollovers?: string[];
      dailyMJentriesAssigned?: number;
      sessionsCompleted?: number;
      hrAssociateStatus?: string;
      hrYearsAt?: string;
      hrAge?: string;
      hrGender?: string;
      hrEthnicity?: string;
      hasSeenHRModal?: boolean;
      name?: string;
      // unlimited number of properties
    };
  };
  rewards: Array<string | number | boolean>;
  scoreEntries: Array<string | number | boolean>;
  missions: Array<MissionObject>;
  /** Props for subscription status, email, current session, and device syncing. */
  user: {
    currentSession: number;
    hasActiveSubscription: boolean;
    mailchimpUserId: string | null; // does this really need `null` as a potential value?
    mailchimpuserid?: string;
    /** Value to be sent to UCS */
    paidThroughDate?: string | null;
    /** All-lowercase version sent back by UCS.*/
    paidthroughdate?: string | null; //
    id?: string;
    email?: string;
    name?: string;
    mailchimpFriendUserId?: string;
    mailchimpCounselorUserId?: string;
    stripeId?: string;
    // many more properties
  };
  token: string;
}

// =============== Subtypes of UCSDownloadResponse =================

export type GTDataStringType = ReturnType<typeof getGTData>;
export type UserObjectType = ReturnType<typeof getUserChildObject>;

/**
 * This is intended to allow adding generic properties to Redux. Not currently working.
 */
export type UCSUserObjectProperties = keyof UserObjectType;

// ===================== UCS API

/**
 * This object represents the UCS api.
 */
export const UCS = {
  signUp: async (authRequest: {
    name: string;
    email: string;
    password: string;
  }) => {
    const response = await fetch(
      'https://upliftserver.com/api/account/register',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(authRequest),
      }
    );

    const result = (await response.json()) as UCSSignupResponse;

    return result;
  },
  signIn: async (authRequest: { email: string; password: string }) => {
    const response = await fetch(
      'https://upliftserver.com/api/account/signin',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(authRequest),
      }
    );

    const result = (await response.json()) as UCSLoginResponse;

    return result;
  },
  download: async (token: string) => {
    const response = await fetch('https://upliftserver.com/api/sync/download', {
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    const result = (await response.json()) as UCSDownloadResponse;
    return result;
  },
  upload: async (
    token: string,
    toUpload: { deviceId: string; lastLogin: Date }
  ) => {
    const response = await fetch('https://upliftserver.com/api/sync/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify(toUpload),
    });

    const result = (await response.json()) as UCSUploadResponse;
    console.log(`Upload response at: ${JSON.stringify(result.timestamp)}`);
    return result;
  },
  coupon: async (authRequest: { email: string; customerId: string }) => {
    console.log(`Auth request is: ${JSON.stringify(authRequest)}`);
    const response = await fetch('https://upliftserver.com/api/stripe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authRequest),
    });

    const result = (await response.json()) as { success: boolean };
    console.log(JSON.stringify(result));
    return result;
  },
};

/**
 * Additional API types
 */

export interface MissionObject {
  id: number;
  name: string;
  istemplate: boolean;
  missiontype: number;
  gemreward: number;
  tasktotal: number;
  taskscompleted: number;
  icon: string;
  url: string;
  eventtocomplete: string;
  iscompleted: boolean;
  /** Value to listen for from UCS */
  scheduledfor?: string | null;
  /** Value to return to UCS */
  scheduledFor?: string | null;
}

/**
 * Misc api req/res types
 */

interface UCSSignupSuccess {
  id: string;
  token: string;
}

interface UCSSignupFailure {
  error: string;
}

type UCSSignupResponse = UCSSignupSuccess | UCSSignupFailure;

interface UCSLoginSuccess {
  id: string;
  token: string;
  lastSyncedDeviceId: string | null;
  lastCompletedSyncDate: Date | null;
  currentSession: number;
  stripeId: string | null;
  hasActiveSubscription: boolean;
  paidThroughDate: string | null;
}

interface UCSLoginFailure {
  error: string;
}

export type UCSLoginResponse = UCSLoginSuccess | UCSLoginFailure;

interface UCSUploadResponse {
  token: string;
  timestamp: Date;
}

// export { UCS };
// export type { UCSDownloadResponse, MissionObject };
